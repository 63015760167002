import React from "react"
import {Row, Container, Col} from 'react-bootstrap'
import Logo from '../../static/varmaorka.png'
import Facebook from '../../static/facebook.png'
import LinkedIn from '../../static/linkedin.png'
import SubscribeForm from "./subscribe"

const Footer = ( ) => {
  return (
    <footer className="footer text-white">
      <Container fluid>
        <Row className="align-items-center">
          <Col md={'auto'} className="d-none pe-4 d-xl-block">
            <img src={Logo} className="img-fluid" alt="logo" />
          </Col>
          <Col md={4} lg={3} xs={12} className="order-md-1 mb-md-0 ms-lg-auto mb-4">
            <SubscribeForm/>
          </Col>
          <Col md={5} lg={4} xs={8} className="text-truncate">
            <p>Katrínartún 2, 17th floor, 105 Reykjavík <br />info@varmaorka.is <br />500817-1430</p>
          </Col>
          <Col md={2} lg={2} xs={4} className="ms-auto order-md-2 d-flex">
            <a href="https://www.facebook.com/varmaorka2017" rel="noreferrer" className="me-md-3 me-2" target="_blank"><img src={Facebook} className="img-fluid" alt="logo" /></a>
            <a href="https://www.linkedin.com/company/varmaorka/" rel="noreferrer" target="_blank"><img src={LinkedIn} className="img-fluid" alt="logo" /></a>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer