import React from "react"
import {Navbar, Container, Nav, NavDropdown} from 'react-bootstrap'
import { useLocation } from '@reach/router';
import {Link, useI18next} from 'gatsby-plugin-react-i18next';
import {Trans} from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby'

const Header = ( ) => {
  const location = useLocation();
  const {languages, originalPath, language} = useI18next();
  return (
    <>
      <Navbar collapseOnSelect className="dark-nav" expand="lg">
        <Container fluid>
          <Navbar.Toggle className={(location.pathname === '/en-US/' || location.pathname === '/' ) ? 'text-white order-2' : 'text-dark order-2'} aria-controls="basic-navbar-nav" />
          <Navbar.Brand href="/"><img src={(location.pathname === '/en-US/'|| location.pathname === '/' ) ? '../../varmaorkaWhite.svg' : '../../varmaorka.svg'} alt="Varmaorka Logo"/></Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto" data-lang={language === 'is' ? 'ÍSLENSKA' : 'ENGLISH'}>
              <Link to={'/projects'} activeClassName="active" className="nav-link" style={(location.pathname === '/en-US/' || location.pathname === '/' ) ? {color: "#FFF"} : {color: "000"}}><Trans>Projects</Trans></Link>
              <Link to={'/about'} activeClassName="active" className="nav-link" style={(location.pathname === '/en-US/' || location.pathname === '/' ) ? {color: "#FFF"} : {color: "000"}}><Trans>About us</Trans></Link>
              <Link to={'/how'} activeClassName="active" className="nav-link" style={(location.pathname === '/en-US/' || location.pathname === '/' ) ? {color: "#FFF"} : {color: "000"}}><Trans>How we work</Trans></Link>
              <Link to={'/news'} activeClassName="active" className="nav-link" style={(location.pathname === '/en-US/' || location.pathname === '/' ) ? {color: "#FFF"} : {color: "000"}}><Trans>News</Trans></Link>
              <NavDropdown title={language === 'is' ? 'ÍSLENSKA' : 'ENGLISH'} className={(location.pathname === '/en-US/' || location.pathname === '/' ) ? 'text-white' : 'text-dark'} id="basic-nav-dropdown" style={(location.pathname === '/en-US/' || location.pathname === '/' ) ? {color: "#FFF"} : {color: "000"}}>
                <NavDropdown.Item><Link to={originalPath} language={languages[0]}><img src={'../../IS_language.png'} alt="Icelandic language"/> ÍSLENSKA</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to={originalPath} language={languages[1]}><img src={'../../English_language.png'} alt="English_language"/> English</Link></NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <div className="text-end language d-block d-lg-none">
              <Link to={originalPath} language={languages[0]}><img src={'../../IS_language.png'} alt="English_language"/> ÍSLENSKA</Link>
              <Link to={originalPath} language={languages[1]}><img src={'../../English_language.png'} alt="Icelandic language"/> English</Link>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export const query = graphql`
query($language: String!){
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Header
