import React from "react"
import Header from "./header"
import Footer from "./footer"
import { Helmet } from 'react-helmet'

const Layout = ({ children, lang, front, title }) => {
  return (
    <>
      <Header lang={lang} front={front}/>
        <main>
          <Helmet title={title} defer={false}/>
          {children}
        </main>
      <Footer lang={lang}/>
    </>
  )
}

export default Layout
