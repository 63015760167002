import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import {Trans} from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby'

export default class SubscribeForm extends React.Component {
    state = {
        email: '',
        message: ''
    };
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const result = await addToMailchimp(this.state.email);
        this.setState({ message: result.msg });
    };
    
    render() {
        return (
            <div>
                <div className="message" dangerouslySetInnerHTML={{ __html: this.state.message}} />
                <form name="subscribeForm" method="POST" id="subscribe-form" className="subscribe-form"  onSubmit={this.handleSubmit}>
                    <legend><Trans>Subscribe to our newsletter:</Trans></legend>
                    <div className="d-flex">
                        <input
                            className="subscribe-email"
                            type="email"
                            name="email"
                            placeholder="Email..."
                            value={this.state.email}
                            onChange={this.handleInputChange}
                        />
                        <button className="button" type="submit">
                        <Trans>Subscribe</Trans>
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

export const query = graphql`
query($language: String!){
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`